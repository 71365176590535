import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class CarritoService {
  data;
  public cart = [];
  public cartItemCount = new BehaviorSubject(0);
  constructor(private alertCtrl: AlertController) { }

  getProducts() {
    return this.data;
  }
  getCart() {
    return this.cart;
  }
  getCartItemCount() {
    return this.cartItemCount;
  }
  comprobarIdEstablecimiento(id) {
    if (this.cart[0]) {
      if (this.cart[0].idEstablecimiento == id) {
        true
      } else {
        return false
      }
    }
    return true
  }
  addProduct(product) {
    if (product.Precio === "0") {
      console.log('Producto Diferente!!');
      console.log(product);
    }
    let comprobar: boolean = this.comprobarIdEstablecimiento(product.idEstablecimiento);
    if (comprobar) {

      let added = false;
      for (let p of this.cart) {
        if (p.objectId === product.objectId) {
          p.amount += 1;
          added = true;
          break;
        }
      }
      if (!added) {
        this.cart.push(product);
        this.addToCartNotif(product);
      }
      this.cartItemCount.next(this.cartItemCount.value + 1);
      var a = JSON.stringify(this.cart);
      localStorage.setItem('cart', a);

    } else {
      this.alertaCarrito();
    }
  }
  addExternalProduct(product) {
    let added = false;
    for (let p of this.cart) {
      if (p.objectId === product.objectId) {
        if (product.amount > 1) {
          p.amount = p.amount + product.amount;
        } else {
          p.amount += 1;
        }
        added = true;
        break;
      }
    }
    if (!added) {
      this.cart.push(product);
    }
    if (product.amount > 1) {
      this.cartItemCount.next(this.cartItemCount.value + (product.amount - 1));
    }
    this.cartItemCount.next(this.cartItemCount.value + 1);
    var a = JSON.stringify(this.cart);
    localStorage.setItem('cart', a);
  }
  addProductStoraged(product) {
    let added = false;
    for (let p of this.cart) {
      if (p.objectId === product.objectId) {
        p.amount += 1;
        added = true;
        break;
      }
    }
    if (!added) {
      this.cart.push(product);
    }
    if (product.amount > 1) {
      this.cartItemCount.next(this.cartItemCount.value + (product.amount - 1));
    }
    this.cartItemCount.next(this.cartItemCount.value + 1);
  }
  decreaseProduct(product) {
    for (let [index, p] of this.cart.entries()) {
      if (p.objectId === product.objectId) {
        p.amount -= 1;
        if (p.amount == 0) {
          this.cart.splice(index, 1);
        }
      }
    }
    this.cartItemCount.next(this.cartItemCount.value - 1);
    var a = JSON.stringify(this.cart);
    localStorage.setItem('cart', a);
  }
  removeProduct(product) {
    for (let [index, p] of this.cart.entries()) {
      if (p.objectId === product.objectId) {
        if (this.cartItemCount.value > 0) {

        }
        this.cartItemCount.next(this.cartItemCount.value - product.amount);
        product.amount = 1;
        this.cart.splice(index, 1);
        var a = JSON.stringify(this.cart);
        localStorage.setItem('cart', a);
      }
    }
  }

  
  getTotal() {
    return this.cart.reduce((i, j) => i + j.Precio * j.amount, 0);
  }
  addToCartNotif(p) {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: p.Nombre + ' agregado al carrito',
      showConfirmButton: false,
      imageUrl: p.foto,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: 'Custom image',
      timer: 1500
    })
  }
  async alertaCarrito() {
    let t = await this.alertCtrl.create({

      cssClass: 'alerta-carrito',
      header: '¡Un establecimiento a la vez!',
      message: 'Tienes items de otro establecimiento!',
      buttons: [
        {
          text: 'Entendido',
          handler: () => {
            //console.log('Confirm Okay');
          }
        }
      ]
    });
    t.present();
  }
}
