import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },{
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'carrito',
    loadChildren: () => import('./pages/carrito/carrito.module').then( m => m.CarritoPageModule)
  },
  {
    path: 'establecimiento',
    loadChildren: () => import('./pages/establecimiento/establecimiento.module').then( m => m.EstablecimientoPageModule)
  },
  {
    path: 'pago',
    loadChildren: () => import('./pages/pago/pago.module').then( m => m.PagoPageModule)
  },
  {
    path: 'pedidos',
    loadChildren: () => import('./pages/pedidos/pedidos.module').then( m => m.PedidosPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./pages/perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'antesala',
    loadChildren: () => import('./pages/antesala/antesala.module').then( m => m.AntesalaPageModule)
  },
  {
    path: 'info-covid',
    loadChildren: () => import('./pages/info-covid/info-covid.module').then( m => m.InfoCovidPageModule)
  },
  {
    path: 'producto',
    loadChildren: () => import('./pages/producto/producto.module').then( m => m.ProductoPageModule)
  },
  {
    path: 'facturacion',
    loadChildren: () => import('./pages/facturacion/facturacion.module').then( m => m.FacturacionPageModule)
  },
  {
    path: 'tipo-banco',
    loadChildren: () => import('./pages/tipo-banco/tipo-banco.module').then( m => m.TipoBancoPageModule)
  },
  {
    path: 'pickup-location',
    loadChildren: () => import('./pages/pickup-location/pickup-location.module').then( m => m.PickupLocationPageModule)
  },
  {
    path: 'detalle-orden',
    loadChildren: () => import('./pages/detalle-orden/detalle-orden.module').then( m => m.DetalleOrdenPageModule)
  },
  {
    path: 'contacto',
    loadChildren: () => import('./pages/contacto/contacto.module').then( m => m.ContactoPageModule)
  },
  {
    path: 'bolsa-empleo',
    loadChildren: () => import('./pages/bolsa-empleo/bolsa-empleo.module').then( m => m.BolsaEmpleoPageModule)
  },
  {
    path: 'promos',
    loadChildren: () => import('./pages/promos/promos.module').then( m => m.PromosPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
