import { Component } from '@angular/core';
import { CarritoService } from './services/carrito.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private cartService: CarritoService) {
    //this.initializeApp();
  }

  ngOnInit() {
    let c = localStorage.getItem('cart');
    let r = JSON.parse(c);
    console.log(r);
    if (r) {
      for (let i = 0; i < r.length; i++) {
        const element = r[i];
        this.cartService.addExternalProduct(element);
      }
    }
  }
  initializeApp() {
    //this.changeDarkMode();
  }
  changeDarkMode() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    if (prefersDark.matches) {
      document.body.classList.toggle('dark');
    }
  }

}
