import { Component, OnInit } from '@angular/core';
import { Map, tileLayer, marker, circle, icon, geoJSON, latLng, Marker } from 'leaflet';
import { Platform, AlertController, ModalController } from "@ionic/angular";
import { Router } from '@angular/router';
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from "@ionic-native/native-geocoder/ngx";
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { DatosUsuario } from 'src/models/datosUsuario.class';
@Component({
  selector: 'app-pickup-location',
  templateUrl: './pickup-location.page.html',
  styleUrls: ['./pickup-location.page.scss'],
})
export class PickupLocationPage implements OnInit {
  datosUbicacion;
  map: Map;
  newMarker: any;
  address: string[];
  address1: string;
  lat: number;
  lng: number;

  latitude: number;
  longitude: number;
  accuracy: number;
  cciLat: number = -0.176608;
  cciLng: number = -78.485157;
  csLat: number = -0.161405;
  csLng: number = -78.485306;
  L: any;
  userUbication: Marker;
  repartidorUbication: Marker;
  comaSano: Marker;
  distance;
  //Geocoder configuration
  geoencoderOptions: NativeGeocoderOptions = {
    useLocale: true,
    maxResults: 5
  };

  geojsonFeature: any = [{
    "type": "LineString",
    "coordinates": [[this.cciLng, this.cciLat], [this.csLng, this.csLat]]
  }];

  MotoIcon = icon({
    iconUrl: '/assets/moto.png',

    iconSize: [25, 25], // size of the icon
    //shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
    //shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
  });
  LocalIcon = icon({
    iconUrl: '/assets/bg/logoPRC.jpg',

    iconSize: [25, 25], // size of the icon
    //shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
    //shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [0, -15] // point from which the popup should open relative to the iconAnchor
  });
  MiUbicacionIcon = icon({
    iconUrl: '/assets/tachuela.png',

    iconSize: [30, 30], // size of the icon
    //shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
    //shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [0, -15] // point from which the popup should open relative to the iconAnchor
  });
  nuevoResultado;
  confirmar: boolean;
  datosEntrega: DatosUsuario = {
    apellido: '',
    fact: '',
    ci: '',
    contrasena: '',
    correo: '',
    detalleDireccion: '',
    direccion: '',
    distancia: '',
    nombre: '',
    objectId: '',
    telefono: '',
    ubicacionGps: '',
    username: ''
  };
  constructor(private geolocation: Geolocation, private geocoder: NativeGeocoder,
    private router: Router, private platform: Platform,
    private alertCtrl: AlertController, private modalCtrl: ModalController) { }
  ngOnInit() {
    //this.getGeolocation();
    this.confirmar = false;
    //this.getLoc();
    //this.getAddress(this.lat,this.lng)
    //this.loadMap();

  }
  reverseGeocode(lat, lng) {
    /*   if (this.platform.is('cordova')) { */
    let options: NativeGeocoderOptions = {
      useLocale: true,
      maxResults: 5
    };
    this.geocoder.reverseGeocode(lat, lng, options)
      .then((result: NativeGeocoderResult[]) => this.nuevoResultado = result[0])
      .catch((error: any) => console.log(error));
    /*     } else {
    console.log('no entro al if de cordova!!')
        } */
  }
  getGeolocation() {
    this.geolocation.getCurrentPosition().then((resp) => {

      this.latitude = resp.coords.latitude;
      this.longitude = resp.coords.longitude;
      this.accuracy = resp.coords.accuracy;

      this.getGeoencoder(resp.coords.latitude, resp.coords.longitude);

    }).catch((error) => {
      //alert('Error getting location' + JSON.stringify(error));
    });
  }
  //geocoder method to fetch address from coordinates passed as arguments
  getGeoencoder(latitude, longitude) {
    this.geocoder.reverseGeocode(latitude, longitude, this.geoencoderOptions)
      .then((result: NativeGeocoderResult[]) => {
        this.address1 = this.generateAddress(result[0]);
        console.log('entra al Geoencoder_: ', this.address1)
      })
      .catch((error: any) => {
        console.log('Error getting location' + JSON.stringify(error));
      });
  }
  //Return Comma saperated address
  generateAddress(addressObj) {
    let obj = [];
    let address = "";
    for (let key in addressObj) {
      obj.push(addressObj[key]);
    }
    obj.reverse();
    for (let val in obj) {
      if (obj[val].length)
        address += obj[val] + ', ';
    }
    return address.slice(0, -2);
  }
  getLoc() {
    this.confirmar = true;
    this.geolocation.getCurrentPosition(
      {
        maximumAge: 1000, timeout: 5000,
        enableHighAccuracy: true
      }
    ).then((resp) => {
      // resp.coords.latitude
      // resp.coords.longitude
      //alert("r succ"+resp.coords.latitude)
      //alert(JSON.stringify( resp.coords));

      this.lat = resp.coords.latitude
      this.lng = resp.coords.longitude
      this.accuracy = resp.coords.accuracy
      var a: any = this.lat;
      var b: any = this.lng;
      var c: any = this.accuracy;
      a = JSON.stringify(a);
      b = JSON.stringify(b);
      this.datosUbicacion = a + ';' + b;
      //console.log('DatosUbicación_: ', this.datosUbicacion);
      //this.getGeoencoder(this.lat,this.lng);
      this.map.setView([this.lat, this.lng], 12)
      if (this.userUbication) {
        this.map.removeLayer(this.userUbication);
        this.userUbication = null;
        this.userUbication = marker([this.lat, this.lng], { draggable: true, icon: this.MiUbicacionIcon }).addTo(this.map)
          .bindPopup('Usted está aquí')
          .openPopup();
        var res = this.userUbication.getLatLng().distanceTo(this.comaSano.getLatLng()).toFixed(0)
        this.distance = parseInt(res);
        this.distance = this.distance / 1000;
        this.distance = Math.round(this.distance * 100) / 100;
        //console.log(this.distance, 'km')
        this.getAddress(this.lat, this.lng);
        if (this.distance > 4) {
          this.alertMessage('Estás muy lejos', 'Lo sentimos, muy pronto expandiremos la cobertura')
          this.confirmar = false;
        }
        //console.log('userUbication_: ',userUbication.getLatLng());
        this.userUbication.on('dragstart', () => {
          this.confirmar = false;
        });
        this.userUbication.on('dragend', () => {
          this.confirmar = true;
          a = this.userUbication.getLatLng().lat;
          b = this.userUbication.getLatLng().lng;
          a = JSON.stringify(a);
          b = JSON.stringify(b);
          this.datosUbicacion = a + ';' + b;
          //console.log('DatosUbicación_: ', this.datosUbicacion);
          this.lat = a;
          this.lng = b;
          //console.log('A_B_: ', a, b)
          var res = this.userUbication.getLatLng().distanceTo(this.comaSano.getLatLng()).toFixed(0)
          this.distance = parseInt(res);
          this.distance = this.distance / 1000;
          this.distance = Math.round(this.distance * 100) / 100;
          //console.log(this.distance, 'km')
          this.getAddress(this.lat, this.lng);
          if (this.distance > 4) {
            this.alertMessage('Estás muy lejos', 'Lo sentimos, muy pronto expandiremos la cobertura')
            this.confirmar = false;
          }
          //console.log('Actualizando_: ',userUbication.getLatLng()) 
        });
      } else {
        this.userUbication = marker([this.lat, this.lng], { draggable: true, icon: this.MiUbicacionIcon }).addTo(this.map)
          .bindPopup('Usted está aquí')
          .openPopup();
        var res = this.userUbication.getLatLng().distanceTo(this.comaSano.getLatLng()).toFixed(0)
        this.distance = parseInt(res);
        this.distance = this.distance / 1000;
        this.distance = Math.round(this.distance * 100) / 100;
        //console.log(this.distance, 'km')
        this.getAddress(this.lat, this.lng);
        if (this.distance > 4) {
          this.alertMessage('Estás muy lejos', 'Lo sentimos, muy pronto expandiremos la cobertura')
          this.confirmar = false;
        }
        //console.log('userUbication_: ',userUbication.getLatLng());
        this.userUbication.on('dragstart', () => {
          this.confirmar = false;
        });
        this.userUbication.on('dragend', () => {
          this.confirmar = true;
          a = this.userUbication.getLatLng().lat;
          b = this.userUbication.getLatLng().lng;
          a = JSON.stringify(a);
          b = JSON.stringify(b);
          this.datosUbicacion = a + ';' + b;
          //console.log('DatosUbicación_: ', this.datosUbicacion);
          this.lat = a;
          this.lng = b;
          //console.log('A_B_: ', a, b)
          var res = this.userUbication.getLatLng().distanceTo(this.comaSano.getLatLng()).toFixed(0)
          this.distance = parseInt(res);
          this.distance = this.distance / 1000;
          this.distance = Math.round(this.distance * 100) / 100;
          //console.log('Redondeado: ', this.distance, 'km')
          this.getAddress(this.lat, this.lng);
          if (this.distance > 4) {
            this.alertMessage('Estás muy lejos', 'Lo sentimos, muy pronto expandiremos la cobertura')
            this.confirmar = false;
          }
          //console.log('Actualizando_: ',userUbication.getLatLng()) 
        });
      }
      /* 
                this.geocoder.reverseGeocode(52.5072095, 13.1452818, this.geoencoderOptions)
        .then((result: NativeGeocoderResult[]) => console.log('Resultado geoencoder_: ',JSON.stringify(result[0])))
        .catch((error: any) => console.log(error));
      
       */

    }, er => {
      //alert("error getting location")
      //alert('Can not retrieve Location')
    }).catch((error) => {
      alert('Error getting location' + JSON.stringify(error));
      //alert('Error getting location - '+JSON.stringify(error))
    });
  }
  // The below function is added
  ionViewDidEnter() {
    this.loadMap();
  }
  // The below function is added
  loadMap() {

    this.map = new Map("mapId").setView([this.csLat, this.csLng], 14);
    tileLayer(/* 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    } */'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 20,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1IjoiY29tYXNhbm8iLCJhIjoiY2thNHRvdHgzMTVrbDNmcjNrNDV6NjhtOSJ9.akyNF_SRu7z9OHUWTkFvow'
    }).addTo(this.map);




    //this.lat = a;
    //this.lng = b;
    circle([this.csLat, this.csLng], {
      color: 'gray',
      fillColor: 'orange',
      fillOpacity: 0.25,
      radius: 4000
    }).addTo(this.map);
    this.comaSano = new Marker([this.csLat, this.csLng], { icon: this.LocalIcon }).addTo(this.map)
      .bindPopup('<b>Plaza Río Coca!</b>').openPopup();

    /*     marker([this.cciLat, this.cciLng], {icon: this.MotoIcon}).addTo(this.map)
        .bindTooltip('<b>Estoy en camino!</b>').openTooltip();
    
    
        geoJSON(this.geojsonFeature).addTo(this.map)
     */
    //PathFinder.findPath(this.geojsonFeature).addTo(this.map);
    this.getLoc();
  }
  ionViewWillLeave() {
    this.distance = '';
    this.map.remove();
    //this.router.ngOnDestroy();
  }
  getAddress(lat: number, long: number) {
    let options: NativeGeocoderOptions = {
      useLocale: true,
      maxResults: 5
    };
    this.geocoder.reverseGeocode(lat, long, options).then(results => {
      this.address = Object.values(results[0]).reverse();
      //console.log('address_: ', this.address);

    })
      .catch((error: any) => {
        console.log('Error getting location' + JSON.stringify(error));
      });
  }
  
  confirmLocation() {
    var a = this.address;
    this.datosEntrega.ubicacionGps = this.datosUbicacion;
    this.datosEntrega.distancia = this.distance;
    var dataEntrega = JSON.stringify(this.datosEntrega);
    localStorage.setItem('datosEntrega', dataEntrega);
    //console.log('datosEntrega_: ', this.datosEntrega);
    this.close();
  }
  async alertMessage(title, detail) {
    let alert = this.alertCtrl.create({
      header: title,
      message: detail,
      buttons: ['Entendido']
    });
    (await alert).present();
  }
  close() {
    this.router.navigate(['carrito']);
  }
}
